import { AfterViewChecked, Component, forwardRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { ModalController, Platform } from '@ionic/angular';
import { UtilService } from '../../services/util.services';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import * as moment from 'moment-timezone';
import { IStafferInvoice } from 'src/modules/onboarding-staffer/interfaces/invoice.interface';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { CompanyDashboardService } from 'src/modules/company-dashboard/services/company-dashboard.service';
import { InvoicesService } from '../../services/invoices.service';

@Component({
  selector: 'app-full-detail',
  templateUrl: './full-detail.component.html',
  styleUrls: ['./full-detail.component.scss'],
})
export class FullDetailComponent implements OnInit {

  @Input() data;
  distanceFromShift;
  jobItem;
  profilePicture;
  description: string;
  invoice;
  userData;
  shiftAddressList: string[] = [];
  bigDeci = this.utilService.bigDeci;
  invoicePaid: boolean = false;
  
  constructor(
    private talentJobFormatService: TalentJobFormatService,
    private modalController : ModalController,
    public utilService: UtilService,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private loadingService : LoadingService,
    @Inject(forwardRef(() => PopupService)) private popupService,
    private talentDashboardService : TalentDashboardService,
    private companydashboardService : CompanyDashboardService,
    private invoiceService: InvoicesService,
  ) { 
  }

  ngOnInit(): void {

    if(this.data.user) 
      this.userData = this.data.user;


    if(this.data.currentInvoice)  {
      this.invoice = this.data.currentInvoice;
      this.generateDescription();
    }
    
    if(this.data.job) {
      let url;
      this.jobItem = this.data.job;
 
      this.jobItem.shiftStartTimeFormatted = moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone).format('dddd MMMM DD');
      this.jobItem.startTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true);
      this.jobItem.endTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true);
      this.shiftAddressList = this.utilService.fetchAddressesFromPatientList(this.jobItem);      
      url = this.utilService.evaluateProfilePictureUrl(this.jobItem, this.invoice)

      if(this.data.job && this.data.job.entity)
        this.profilePicture = this.talentJobFormatService.getProfilePic(url);   
      else
        this.profilePicture = 'assets/images/staffy-gray.png'

      this.distanceFromShift = this.utilService.calculateDistanceFromShift(this.jobItem.companyAddress, this.userData.address);
    }
    
  }

  downloadInvoice() {
    this.modalController.dismiss({download: true});
  }

  generateDescription() {
    this.description = this.talentJobFormatService.getJobDescription(this.invoice);
  }

  updateInvoiceMarkAsPaid() {
    this.modalController.dismiss({markAsPaid: true})
  }

  goBack() {
    this.modalController.dismiss({ 
      invoicePaid: this.invoicePaid
    });
  }

  // Check if user has agreed on fee deduction
  private async feeDeductionHandle(): Promise<boolean> {

    // If payment pay after 5 days then ignore Admin fee check
    if (this.invoice.scheduledDatePassed) {
      return true;
    } else {
      const hasUserAgreedOnFee = await this.feeWillDeductIfYouPayImmediatly(this.invoice);
      return hasUserAgreedOnFee;
    }
  }


  // Show fee deduction modal
  private async feeWillDeductIfYouPayImmediatly(invoice): Promise<boolean> {
    const staffer = invoice.jobs[0].staffers[0].staffer;
    const job = invoice.jobs[0].job;
    const hasContractorTaxEnabled = staffer.approveContractorTaxationFromAdmin && staffer.enableContractorTaxFromApp;
    const tax = hasContractorTaxEnabled ? this.utilService.contractorShiftTax(job.province, invoice) : 0;

    const deductStaffyPayFee =  this.utilService.calculateStaffyPayFee(invoice.subTotal)
    const amountAfterFee  = Number(this.bigDeci(invoice.subTotal).minus(this.bigDeci(deductStaffyPayFee)))
    const totalPayingAmount =  Number(this.bigDeci(amountAfterFee).plus(this.bigDeci(tax)))

    const taxText = hasContractorTaxEnabled ? `, and the new amount including HST will be $${tax}. You will be paid a total of $${totalPayingAmount} including HST` : '';//`and the new tax amount would be $${tax}. you will be paid a total of $${this.totalPayingAmount} including HST `:'';
    return new Promise(async (resolve, rejectPromise) => {
      this.popupService.showModal(
        {
          heading: `StaffyPay Fee`,
          message:
            `Please note that the Staffypay fee for this invoice will be $${deductStaffyPayFee}${taxText}. Please confirm.`,
          btn: 'ACCEPT',
          rejectBtnText: 'Reject',
          imgURL: 'assets/images/sorry1.png',
          navigateRoute: null
        },
        () => resolve(true),
        () => resolve(false)
      );
    });
  }


  //  Pay Staffer Invoice if all checks pass
  async payStafferInvoice() {
    if (this.invoice.status === 'unpaid' && moment.duration(this.invoice.timeTracked).asHours() <= 14) {

      const sendPayment = await this.feeDeductionHandle();

      if (sendPayment) {
        this.payInvoice(this.invoice);
      }
    } else {

      this.popupService.showModal(
        {
          heading: 'Oops!',
          message: 'Sorry, Please try again later',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/sorry1.png'
        }
      );
    }
  }

  

  // call payStaffy API for paying invoice

  async payInvoice(invoice: IStafferInvoice) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.talentDashboardService.payStaffy(
        invoice.jobs[0].staffers[0].staffer._id,
        invoice._id);

      let updatedInvoice = await this.companydashboardService.getInvoice(invoice._id);
      updatedInvoice = this.invoiceService.formatInvoice(updatedInvoice);
      this.invoice = updatedInvoice

      this.invoicePaid = true;

      this.popupService.showModal({
        heading: 'Success',
        message: 'Invoice marked as paid',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/request-sent.png'
        
      });

    } catch (e) {
      console.log(e);
      this.popupService.showModal({
        heading: 'Sorry',
        message: typeof e.error === 'string' ? e.error : 'Something went wrong, please try again later',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });

    } finally {
      await this.loadingService.hideLoading(loading);

    }

  }

}
