<div class = "goBack" (click) = "goBack()"><i class = "fa fa-arrow-left"></i></div>

<div class = "outer center-content" *ngIf = "jobItem">
  <div class = "image-section">
    <img class="profile-pic" src="{{profilePicture}}">
  </div>
  <div class = "wrapper center-content">
    <div class = "basicInfo center-content">
      <p class = "name" *ngIf = "jobItem.entity">{{jobItem.entity.name}}</p>
      <p class = "skill">{{ ('SKILLS.' + jobItem?.skill) | translate }}</p>
    </div>

    <span class = "general-book sub-skill" *ngIf="jobItem.subSkills && jobItem.subSkills.length">
      <span class = "mont-bold-font display-content">Subskill Required: </span>{{ jobItem.subSkills.join(", ") }}
    </span>
     
    <div class = "serial-number general-regular" *ngIf = "invoice?.serialNo">
      Invoice No. {{ invoice.serialNo }}
    </div>

    <div class = "scrolling-container">
      <div class = "shift-section">
        <div>
         
          <div class = "general-book"><span>
            <i class = "fas fa-calendar"></i><span class = "general-book-bold">On </span>{{ jobItem.shiftStartTimeFormatted }}
            </span>
          </div>
          <div class = "general-book"><i class = "fas fa-clock"></i><span class = "general-book-bold">From </span> {{ jobItem.startTime}} - {{jobItem.endTime}}</div>
          <div class = "general-book"><img class = "unitNumber" src = "assets/images/square-u.png"><span class = "general-book-bold">Unit Number: </span> {{ jobItem.unitNumber}}</div>
          <span class = "general-book-bold"><i class = "fas far fa-money-bill-alt	"></i>${{jobItem?.stafferRate.toFixed(2) ? jobItem?.stafferRate.toFixed(2) : jobItem?.rate.toFixed(2)}}/hour</span>
          <span class = "general-book" *ngIf = "jobItem?.travelTime"><i class="fas fa-solid fa-bus"></i>Transit allowance: </span><span *ngIf = "jobItem?.travelTime" class = "general-book-bold"> {{ jobItem?.travelTime}} hr</span>
          <span class = "general-book" *ngIf = "jobItem?.break"><i class="fas fa-money-check"></i>Unpaid break: </span><span *ngIf = "jobItem?.break" class = "general-book-bold"> {{ jobItem?.break?.unpaid ? jobItem?.break.unpaid: 'No'}}</span>
          <span class = "general-book" *ngIf = "jobItem?.isTipIncluded"><i class="fas fa-solid fa-folder-plus"></i>Includes Tip </span>
          <span class = "general-book" *ngIf = "jobItem?.isStatPay && jobItem?.isStatPay == true"><i class="fas fa-calendar"></i>Stat Holiday Pay</span>
          
          <div class = "address" *ngIf = "!jobItem?.isMultiAddressShift">
            <span class = "general-regular">
            <i class = "fas fa-map-marker"></i><span class = "general-book-bold">At </span>{{jobItem.companyAddress?.street}} - {{ distanceFromShift}} km away</span>
          </div>
    
          <app-shift-multi-address-list
          *ngIf = "jobItem?.isMultiAddressShift"
          [shiftAddressList] = "shiftAddressList">
          ></app-shift-multi-address-list>
          
          <span *ngIf = "jobItem?.entity && (jobItem?.entity.isCovidAffected == true || jobItem?.entity.isCovidAffected == false)">
            <span class = "general-book" ><i class="fas fa-asterisk"></i>Covid affected: </span>
            <span *ngIf = "jobItem?.entity.isCovidAffected == true" class = "general-book-bold"> Positive</span>
            <span *ngIf = "jobItem?.entity.isCovidAffected == false" class = "general-book-bold"> Negative</span>
          </span> 
        </div>
        <br/>
        <div class = "general-book" *ngIf = "jobItem.shiftNotes"><span class = "general-book-bold">Shift notes: </span><span [innerHTML]="jobItem.shiftNotes | sanitizeHtml"></span></div>
        <br/>
        <div class = "general-book" *ngIf = "(jobItem.entity && jobItem.entity.siteNotes) || jobItem.siteNotes"><span class = "general-book-bold">Site notes: </span>{{ jobItem.entity.siteNotes || jobItem.siteNotes}}</div>
        
      </div> 

      <div class = "detail-section" *ngIf = "invoice">
        <div class = "notes">
          <div class = "text">
            <p class = "general-book-bold left-align">Description</p>
            <p class = "general-regular">{{ description }} </p>
          </div>

          <div class = "spacing">
            <span class = "general-book-bold left-align">Hourly rate: </span><span class = "general-regular">${{ invoice.rate }}</span><span class = "general-regular" *ngIf="invoice?.overtimeHours"> overtime paid at {{ invoice.overtimeRate}}S</span>
          </div>

          <div class = "spacing">
            <span class = "general-book-bold left-align">Time tracked: </span><span class = "general-regular">{{ invoice.trackedHours }}</span>
            <span class = "general-regular" *ngIf="invoice?.overtimeHours">includes {{ invoice.overtimeHours }} overtime hours tracked</span>
          </div>

          <div class = "spacing">
            <span class = "general-book-bold left-align">Shift Amount</span>
            <span class = "general-regular"> {{ invoice.shiftAmount }} {{ invoice.currency }}D </span>
          </div>

          <div *ngIf = "invoice?.manualHrsReviewFee" class = "spacing">
            <span class = "general-book-bold left-align">Manual hours review fee</span>
            <span class = "general-regular"> - {{ invoice.manualHrsReviewFee }} {{ invoice.currency }}D </span>
          </div>

            
          <div *ngIf = "invoice?.penaltyAmount" class = "spacing">
            <span class = "general-book-bold left-align">Cancellation fee</span>
            <span class = "general-regular"> - {{ invoice.penaltyAmount }} {{ invoice.currency }}D </span>
          </div>

          <div *ngIf = "invoice?.backgroundCheckAmount" class = "spacing">
            <span class = "general-book-bold left-align">Background check fee</span>
            <span class = "general-regular"> - {{ invoice.backgroundCheckAmount }} {{ invoice.currency }}D </span>
          </div>

          <div *ngIf = "invoice?.staffyPayAdminFee" class = "spacing">
            <span class = "general-book-bold left-align">StaffyPay fee</span>
            <span class = "general-regular"> - {{ invoice.staffyPayAdminFee }} {{ invoice.currency }}D </span>
          </div>

          <div class = "spacing">
            <span class = "general-book-bold left-align">Subtotal</span>
            <span class = "general-regular"> {{ invoice.subTotal }} {{ invoice.currency }}D </span>
          </div>

          <div *ngIf="invoice?.contractorTax > 0" class = "spacing">
            <span class = "general-book-bold left-align">Tax {{ invoice.taxName ? ' (' + invoice.taxName + ')' : '' }}
            </span>
            <span class = "general-regular"> {{ invoice.contractorTax }} {{ invoice.currency }}D </span>
          </div>


          <div class = "name wide-spacing">
            {{ invoice.total }} {{ invoice.currency }}D
          </div>
        </div>
      </div>
      
      <div class = "button-section" *ngIf = "invoice"> 
        <div class = "invoice-status" [ngClass] = "{'unpaid' : invoice?.status == 'unpaid', 'paid' : invoice?.status == 'paid'}">
        {{ invoice.status }}
        </div>

      <div class="button-stack">
        <button class = "invoice-status paid"
          *ngIf = "
            invoice?.status === 'unpaid' &&
            invoice?.jobs[0].staffers[0].staffer.hasStaffyPayEnabled &&
            !invoice?.hidePayNowButtonForOldInvoices"
          [disabled]="invoice?!invoice?.enablePayNowOption:false"
          (click)="payStafferInvoice()" 
        >
          {{invoice.applyInvoiceLabel}} {{ invoice.showStaffyPayFeeOnLabel ? '($'+utilService.calculateStaffyPayFee(invoice.subTotal) +')': '' }}
        </button>
    
        <button class = "invoice-status paid"
          *ngIf="invoice?.status === 'unpaid' && !invoice?.jobs[0].staffers[0].staffer.hasStaffyPayEnabled"
          (click)="updateInvoiceMarkAsPaid()">
          {{ 'INVOICES.Mark as Paid' | translate }}
        </button>
      </div>

      <div *ngIf = "invoice?.staffyPayScheduledDate">
        <span class = "general-bold">Scheduled date: </span><span class = "general-regular">{{ invoice.staffyPayScheduledDate}}</span>
      </div>
      </div>
    </div>

    <div class = "btn-section" *ngIf = "invoice" >
        <ion-button color = "basic" class = "login-btn" type="submit" (click) = "downloadInvoice()">
          <div class = "login-txt">Download</div>
        </ion-button>
    </div>
  </div>
</div>