import { Injectable } from '@angular/core';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { IClientInvoice } from '../interfaces/client-invoice.interface';
import { IImportReferral } from 'src/modules/shared/interfaces/referral.interface';

@Injectable({ providedIn: 'root' })

export class CompanyDashboardService {

    constructor(
        private httpPromiseService: HttpPromiseService,
    ) { }

    public getInvoices(
        entityId: string,
        filter: 'paid,marked as paid,late cancellation penalty paid' | 'unpaid,partially paid,late cancellation penalty unpaid' | 'all',
        toSkipItems: number,
        pageSize: number) {
        return this.httpPromiseService.httpGetRequest(
            `/api/invoicesNewModel/${entityId}?type=employer&filterByStatus=${filter}&toSkipItems=${toSkipItems}&pageSize=${pageSize}`,
        );
    }

    public getInvoicesForCreditNotes(
        entityId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/invoicesNewModel/${entityId}?type=credit note employer`
        );
    }
    public getReceipts(
        entityId: string,
        toSkipItems: number,
    ) {
        return this.httpPromiseService.httpGetRequest(
            `/api/receipts/fetchReceiptsForInvoicesAndReceipts/all?entity=${entityId}&toSkipItems=${toSkipItems}&pageSize=10`,
        );
    }

    public getTeams(
        entityId: string,
    ) {
        return this.httpPromiseService.httpGetRequest(
            `/api/teams/allTeams/${entityId}`,
        );
    }
    public createTeam(
        data: {
            staffers: string[],
            name: string,
            entityId: string,
        }
    ) {
        return this.httpPromiseService.httpPostRequest(
            `/api/teams/createTeam`, data
        );
    }
    public updateTeam(
        teamId: string,
        data: {
            teamStaffers: string[],
        }
    ) {
        return this.httpPromiseService.httpPatchRequest(
            `/api/teams/updateTeam/${teamId}`, data
        );
    }

    public deleteStafferFromTeam(
        teamId: string,
        stafferId: string,
    ) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/teams/deleteStaffer/${teamId}/${stafferId}`,
        );
    }
    public deleteTeam(
        teamId: string,
    ) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/teams/removeTeam/${teamId}`,
        );
    }

    public getStaffers(
        province: string,
    ) {
        return this.httpPromiseService.httpGetRequest(
            `/api/teams/allTeamMembers/${province}`,
        );
    }

    public getReceiptById(
        id: string,
    ) {
        return this.httpPromiseService.httpGetRequest(
            `/api/receipts/fetchReceiptById/${id}`,
        );
    }

    public getAccountStatement(
        entityId: string,
        city: string,
    ) {
        return this.httpPromiseService.httpGetRequest(
            `/api/invoicesNewModel/fetchInvoicesForCompanyAccountStatementWithCreditNotes/${entityId}/${city}`,
        );
    }

    public generateInvoicePdf(invoice: IClientInvoice) {
        return this.httpPromiseService.httpPostRequest(
            `/api/invoicesNewModel/invoice/sendInvoiceAsPDF`, { invoice },
        );
    }

    public sendReferralInviteToContacts(contacts: IImportReferral[], referralId: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/refferals/`, { contacts, referralId },
        );
    }

    public getInvoice(invoiceId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/invoicesNewModel/invoice/${invoiceId}` ,
        );
    }

    public paySelectedInvoices(
        userId  : String, 
        token : String,
        invoiceIds : Array<String>,
        amount : Number
    ) {
        return this.httpPromiseService.httpPostRequest(
            `/api/invoicesNewModel/payInvoiceDirectlyFromCard/payFilter/invoices/all` ,
            {
                user  : userId, 
                token : token,
                invoices : invoiceIds,
                amount : amount
            }
        );
    }

    public payAllUnpaidInvoices(invoiceIds : Array<String>) {
        return this.httpPromiseService.httpPostRequest(
            `/api/invoicesNewModel/pay/allUnpaidInvoices` ,
            {
                'invoices': invoiceIds, 
                'actionTaken' : "employer"
            }
        );
    }

    public redeemCode(
        code: string,
        walletAmount: number,
        timeZone: string,
        userId: string,
    ) {
        return this.httpPromiseService.httpPostRequest(
            `/api/walletTransactions`, { code, timeZone, userId, walletAmount },
        );
    }
    public updateEntity(
        entityId: string,
        data: {
            address: any,
            phoneNumber: string,
            name: string,
            email: string,
            contactPerson: string,
            siteNotes: string,
            description: string,

        }
    ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/entitys/${entityId}`, data
        );
    }

    /**
     * 
     * @description update client  password
     * @param userId 
     * @param data 
     * @returns 
     */
    
    public updatePassword(
        userId: string,
        data: {
            oldPassword: string,
            newPassword: string,
        }
    ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}/password`, data
        );
    }

    public getIndustries(province: string, companyType: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/industries?hasBroadcastShift=true&province=${province}&companyType=${companyType}` ,
        );
    }

    public getGeocodeFromGoogleApi(postalCode: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/geocode/?postalCode=${postalCode}` ,
        );
    }

    public setCertificateRequirement(data: {
            entityId: string,
            issuerCompany: string,
            referenceNumber: Array<String>,
            type: string
        }) {
        return this.httpPromiseService.httpPostRequest(
            `/api/certificateRequirements`, data
        );
    }

    public getCertificateRequirement(entityId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificateRequirements/${entityId}`
        );
    }

    public getEntityCertificateRequirements(entityId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificateRequirements/${entityId}/requirements`
        );
    }

    public getCertificateRequirementOfClient(entityId: string, brand?: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificateRequirements/client/${entityId}/${brand}`
        );
    }

    public getMaskBrand() {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/getMaskBrands`
        );
    }

    public getMaskModels(brand: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/getMaskModels/${brand}`
        );
    }

    public getAllSettings() {
        return this.httpPromiseService.httpGetRequest(
            `/api/settings`
        );
    }

    public getClientDashboardCardsData(entityId:string,timeZone: string){
        return this.httpPromiseService.httpGetRequest(
            `/api/v2/jobs/stats/client-shifts-summary?entityId=${entityId}&timezone=${timeZone}`
        );
    }

}
